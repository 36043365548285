import styled from '@emotion/styled';
import cx from 'classnames';
import { filter, map } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BankLabel, CopyText, PaymentTypeLabel } from 'components';
import { useCurrencies } from 'hooks';
import { Requisites } from 'types';
import { formatUtils } from 'utils';

type Props = {
  className?: string;
  compact?: boolean;
  cardInfo?: string;
  phone?: string;
  paymentTypeId?: string;
  bankId?: string;
  cardholder?: string;
  fiatCurrencyId?: string;
  canCopy?: boolean;
  swiftBic?: string;
  bic?: string;
  email?: string;
  idCard?: string;
  beneficiaryName?: string;
  accountNumber?: string;
  name?: string;
  expirationDate?: string;
  accountLastDigits?: string;
  requisite?: Requisites;
  bankCode?: string;
  paymentTypeCode?: string;
};

export const StyledBankLabel = styled(BankLabel)`
  font-weight: 500;
`;

export const RequisitesInfo: React.FC<Props> = ({
  className,
  compact,
  canCopy = true,
  requisite,
  bankCode,
  paymentTypeCode,
  ...rest
}) => {
  const { t } = useTranslation();

  const { getFiatCurrencyCode } = useCurrencies();
  const {
    cardInfo,
    phone,
    paymentTypeId,
    bankId,
    cardholder,
    fiatCurrencyId,
    swiftBic,
    bic,
    email,
    idCard,
    beneficiaryName,
    accountNumber,
    name,
    expirationDate,
    accountLastDigits,
  } = useMemo(() => requisite || rest, [requisite, rest]);

  const requisites = useMemo(
    () => (
      <Fragment>
        {cardInfo && (
          <CopyText
            disabled={!canCopy}
            text={formatUtils.formatCardInfo(cardInfo)}
            iconColor="disabled"
          />
        )}
        {expirationDate && (
          <CopyText
            disabled={!canCopy}
            text={expirationDate}
            iconColor="disabled"
          />
        )}
        {phone && (
          <CopyText disabled={!canCopy} text={phone} iconColor="disabled" />
        )}
        {swiftBic && (
          <CopyText disabled={!canCopy} text={swiftBic} iconColor="disabled" />
        )}
        {bic && (
          <CopyText
            disabled={!canCopy}
            text={`${t('features.form_builder.fields.bic.label')}: ${bic}`}
            iconColor="disabled"
          />
        )}
        {email && (
          <CopyText disabled={!canCopy} text={email} iconColor="disabled" />
        )}
        {idCard && (
          <CopyText disabled={!canCopy} text={idCard} iconColor="disabled" />
        )}
        {beneficiaryName && (
          <CopyText
            disabled={!canCopy}
            text={beneficiaryName}
            iconColor="disabled"
          />
        )}
        {accountNumber && (
          <CopyText
            disabled={!canCopy}
            text={accountNumber}
            iconColor="disabled"
          />
        )}
      </Fragment>
    ),
    [
      t,
      cardInfo,
      canCopy,
      phone,
      swiftBic,
      bic,
      email,
      idCard,
      beneficiaryName,
      accountNumber,
      expirationDate,
    ],
  );

  const compactMainProperties = useMemo(
    () =>
      filter([
        name,
        fiatCurrencyId ? getFiatCurrencyCode(fiatCurrencyId) : null,
        paymentTypeId || paymentTypeCode ? (
          <PaymentTypeLabel
            paymentTypeId={paymentTypeId}
            paymentTypeCode={paymentTypeCode}
          />
        ) : null,
        bankId || bankCode ? (
          <BankLabel bankId={bankId} bankCode={bankCode} />
        ) : null,
        cardholder,
      ]),
    [
      bankId,
      bankCode,
      cardholder,
      fiatCurrencyId,
      getFiatCurrencyCode,
      paymentTypeId,
      paymentTypeCode,
      name,
    ],
  );

  if (compact) {
    return (
      <div className={className}>
        {map(compactMainProperties, (value, index) => (
          <span className="tw-truncate" key={index}>
            {index !== 0 && ' | '}
            {value}
          </span>
        ))}

        <div className="tw-flex tw-flex-col">{requisites}</div>
      </div>
    );
  }

  return (
    <div className={cx(className, 'tw-flex tw-flex-col')}>
      {name && (
        <span className="tw-text-sm tw-mb-2 tw-font-semibold">{name}</span>
      )}
      {getFiatCurrencyCode(fiatCurrencyId)}
      {(paymentTypeId || paymentTypeCode) && (
        <PaymentTypeLabel
          paymentTypeId={paymentTypeId}
          paymentTypeCode={paymentTypeCode}
        />
      )}
      {(bankId || bankCode) && (
        <StyledBankLabel bankId={bankId} bankCode={bankCode} />
      )}
      {requisites}
      {cardholder && <div>{cardholder}</div>}
      {accountLastDigits && <div>{accountLastDigits}</div>}
    </div>
  );
};
