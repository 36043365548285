import {
  Autocomplete,
  MenuItem,
  TextFieldProps,
  TextField as TextFieldMui,
} from '@mui/material';
import { Field, useField, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { every, find, isNil, isString, map, sortBy } from 'lodash';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

export type FormikSelectOption = {
  label: React.ReactNode | string;
  value: string;
  disabled?: boolean;
};

const AUTOCOMPLETE_MIN_OPTIONS = 10;

type Props = {
  label: React.ReactNode | string;
  name: string;
  options: FormikSelectOption[];
  noneOption?: boolean;
  showMetaError?: boolean;
  autocomplete?: boolean;
  onChange?: (value: string) => void;
} & Omit<Partial<TextFieldProps>, 'onChange'>;

export type { Props as FormikSelectProps };

export const FormikSelect: React.FC<Props> = ({
  label,
  name,
  options,
  noneOption,
  showMetaError,
  autocomplete = true,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.formik_select',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const formik = useFormikContext();

  const [field, meta, helpers] = useField(name);
  const selectedOption = useMemo(
    () => find(options, (option) => option.value === field.value),
    [field.value, options],
  );
  const [prevSelectedOption, setPrevSelectedOption] = useState(selectedOption);
  const [inputValue, setInputValue] = useState(
    selectedOption ? String(selectedOption.label) : '',
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isSelectedDisabled = useMemo(
    () => find(options, { value: field.value })?.disabled,
    [field.value, options],
  );

  const noneOptionConfig = useMemo(
    () => ({
      value: '',
      label: t('none_option'),
    }),
    [t],
  );

  const asAutocomplete = useMemo(
    () =>
      autocomplete &&
      every(options, (option) => isString(option.label)) &&
      options.length >= AUTOCOMPLETE_MIN_OPTIONS,
    [autocomplete, options],
  );

  const autocompleteOptions = useMemo(() => {
    let result = options;
    if (noneOption) {
      result = [noneOptionConfig, ...result];
    }
    return result;
  }, [options, noneOption, noneOptionConfig]);

  const handleAutocompleteChange = useCallback(
    (value: string) => {
      helpers.setValue(value);
      rest.onChange?.(value);
    },
    [helpers, rest],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.handleChange(e);
      rest.onChange?.(e.target.value);
    },
    [formik, rest],
  );

  useEffect(() => {
    if (asAutocomplete && !isNil(field.value)) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [asAutocomplete, field.value]);

  if (asAutocomplete) {
    return (
      <Autocomplete
        options={autocompleteOptions}
        getOptionLabel={(option) =>
          option.value === '' ? noneOptionConfig.label : String(option.label)
        }
        renderInput={(params) => (
          <TextFieldMui
            {...params}
            {...rest}
            label={label}
            size="small"
            inputRef={inputRef}
            value={inputValue}
            onBlur={() => {
              if (isNil(field.value) && inputValue) {
                setInputValue(
                  prevSelectedOption?.label
                    ? String(prevSelectedOption?.label)
                    : '',
                );
                handleAutocompleteChange(prevSelectedOption?.value || '');
              }
            }}
            onChange={(e) => {
              if (!isNil(field.value)) {
                setPrevSelectedOption(selectedOption);
                handleAutocompleteChange(null as any as string);
              }
              setInputValue(e.target.value);
            }}
          />
        )}
        value={
          find(autocompleteOptions, { value: field.value }) ||
          (null as any as FormikSelectOption)
        }
        inputValue={inputValue}
        disableClearable
        fullWidth
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        onChange={(_, newValue, reason) => {
          if (newValue && newValue.value === '') {
            handleAutocompleteChange('');
            setInputValue('');
          } else {
            handleAutocompleteChange(newValue ? newValue.value : '');
            setInputValue(newValue ? String(newValue.label) : '');
          }
        }}
      />
    );
  }

  return (
    <Field
      {...rest}
      {...(rest.onChange && { onChange: handleChange })}
      component={TextField}
      name={name}
      label={label}
      select
      size="small"
      {...(isSelectedDisabled && {
        error: true,
        helperText: tCommon('errors.invalid'),
      })}
      {...(showMetaError &&
        meta.error && {
          error: true,
          helperText: meta.error,
        })}
    >
      {noneOption && (
        <MenuItem value={noneOptionConfig.value}>
          {noneOptionConfig.label}
        </MenuItem>
      )}
      {map(sortBy(options, 'disabled'), (option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};
