import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { Orders } from 'features/payout-orders';

export const TraderCancelledOrders: React.FC = () => (
  <Orders
    tab={OrdersTab.Cancelled}
    additionalColumns={[
      AdditionalOrderColumn.CustomerContacts,
      AdditionalOrderColumn.CustomerRequisites,
      AdditionalOrderColumn.CancelReason,
    ]}
  />
);
